$--point-list-bg-color: #F4F4F4;

  .device-dialog {
    max-width: 1000px !important;
  }

  .device-type {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    span {
      flex-shrink: 0;
    }
    .el-select {
      padding: 2px;
    }
  }

  .device-content {
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    height: 500px;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .device-item {
    width: 150px;
    height: fit-content;
    background-color: white;
    padding: 6px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-shadow: 2px 2px 5px #e8e8e8;
    border: 2px solid transparent;

    img {
      height: 150px;
      -webkit-user-drag: none;
    }

    span {
      white-space: pre-wrap;
      text-align: center;
    }
  }

  .device-item.selected {
    box-sizing: content-box;
    border: 2px solid var(--el-color-primary-light-3);
  }

  .el-dialog__body {
    padding-top: 0;
  }
