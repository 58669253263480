$--point-list-bg-color: #F4F4F4;

  $--menu-width: 70px;
  .menu-item {
    margin-left: 0px;
    border: 1px solid #e4e4e4;
    width: $--menu-width;
    height: 28px;
  }

  .right-menu-area {
    z-index: 9999999999;
    display: flex;
    flex-direction: column;
    position: fixed;
    width: $--menu-width;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  }
  ::v-deep(.el-button) {
    border: none;
  }
  ::v-deep(.el-dropdown-menu__item) {
    height: var(--menu-height);
  }
  ::v-deep(.el-dropdown-menu__item+.el-dropdown-menu__item){
    border-top: 1px solid var(--el-color-primary-light-9);
  }
  ::v-deep(.el-dropdown-menu__item:not(.is-disabled):focus) {
    background-color: white;
  }
