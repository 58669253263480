<template>
  <div style="gap: 5px;display: flex;flex-direction: column;text-align-last: start;height: inherit;">
    <div class="path-panel-wrap">
      <el-dialog
          v-model="pathDialogModel"
          :append-to-body="false"
          width="95%"
          title="路径规划"
          align-center
      >
          <el-radio-group v-model="pathModel" style="flex-direction: row;gap: 5px" >
            <el-radio label="auto" size="large" border style="margin:0px">自动规划</el-radio>
            <el-radio label="manual" size="large" border>手动规划</el-radio>
          </el-radio-group>
        <div class="create-panel">
          <auto-path-panel v-if="pathModel == 'auto'" @on-success="pathModel = 'auto';pathDialogModel = false" ref="autoPathPanel"/>
          <manual-path-panel v-else-if="pathModel == 'manual'"/>
        </div>
      </el-dialog>
      <span>轨迹列表</span>
      <div style="text-align-last: right">
        <el-button @click="pathDialogModel = true" type="primary" v-if="false">新增轨迹</el-button>
      </div>
      <el-table :data="robot?.paths || []" stripe>
        <el-table-column prop="name" label="名称">
          <template #default="scope">
            <sensitive-input v-model:model="scope.row.name" :maxlength="15" :before-change="onPathNameBeforeChange"/>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <del-btn @delConfirm="handleDelPath(scope.row)" title="是否删除该条轨迹?">
                <el-button>删除</el-button>
            </del-btn>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script setup lang='ts'>
  import {useWork} from "@/store/modules/work";
  import AutoPathPanel from "@/views/design/components/AutoPathPanel.vue"
  import ManualPathPanel from "@/views/design/components/ManualPathPanel.vue"
  import DelBtn from "@/components/DeleteBtn"
  import SensitiveInput from "@/components/SensitiveInput"

  import {Robot} from "@/model";
  import {defineProps} from 'vue'

  const store = useWork()
  const pathMode = ref('')
  const pathModel = ref('auto')
  const pathDialogModel = ref(false)
  const props = defineProps({
    robot: {
      type: Robot,
      required: true
    },
    onPathNameBeforeChange:{
      type:Function,
      default: ()=>true
    }
  })
  function handleDelPath(path) {
    props.robot.delPath(path.id)
  }
  const updateAutoPathName = ()=>{
    if (autoPathPanel.value) {
      autoPathPanel.value.refreshPathFromName()
    }
  }
  const autoPathPanel = ref()
  onBeforeUpdate(()=>{
    updateAutoPathName()
  })
</script>

<style scoped>
  .path-panel-wrap {
    position: relative;
    height: 100%;
  }

  ::v-deep(.el-overlay) {
    position: absolute;
  }

  ::v-deep(.el-overlay-dialog) {
    position: absolute;
  }

  .activeAuto {
    height: 300px;
  }

  .activeManual {
    height: 500px;
  }
  .create-panel {
    margin-top: 10px;
  }
</style>