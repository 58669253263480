$--point-list-bg-color: #F4F4F4;

.device-tree {
  max-width: 500px;
  /*flex-shrink: 0;*/
  transition: max-width 0.2s;
}

.device-tree-close {
  @extend .device-tree;
  max-width: 0px;
}

$top-button: 20px;

.top-button {
  display: flex;
  height: $top-button;
  justify-content: center;
  padding: 10px;
  flex-shrink: 0;

  svg {
    width: 12px;
    margin-right: 5px;
  }

  span {
    font-size: 12px;
  }
}

.tree-border {
  /*border: 1px solid var(--el-color-primary-light-9);*/
  /*border-radius: 10px;*/
  /*margin: 5px;*/
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    padding-right: 8px;
    color: var(--el-color-dark);
  }
}

.icon {
  font-size: 12px;
  fill: var(--el-color-dark);
}

.down-tree {
  flex: 1;
  display: block;
  overflow-y: scroll;
  height: calc(100% - $top-button - 20px);
}

::v-deep(.el-tree-node:focus>.el-tree-node__content) {
  background-color: transparent;
}

::v-deep(.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content) {
  border-radius: 10px;
  background-color: var(--el-color-primary-light-9);
}

::v-deep(.el-tree>.el-tree-node) {
  border: 1px solid var(--el-color-primary-light-9);
  border-radius: 10px;
  margin: 5px;
}

::v-deep(.el-tree-node:hover > .el-tree-node__content) {
  border-radius: 10px;
}

::v-deep(.el-dropdown) {
  width: 100%;

  div {
    user-select: none;
  }
}
