$--point-list-bg-color: #F4F4F4;

.impl-wrap {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  gap: 5px;
  padding: 5px;
  position: relative;
  user-select: none !important;
}

.header-info-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  flex-shrink: 0;
  border-radius: 10px;
  gap: 10px;
}

.header-info {
  justify-content: start;
  height: 100%;
  width: auto;
  padding: 5px;
  flex-grow: 1;
}

.info-header {
  line-height: 15px;
  padding: 2px;
  background: #D3D1E6;
  border-radius: 5px;
  text-align: center;
}

.info-main {
  margin-left: 3px;
}

.slider-panel-wrap {
  box-sizing: border-box;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
}

.center-panel-wrap {
  display: flex;
  flex-direction: column;
  flex: 1;
  box-sizing: border-box;
  padding: 5px;
  background-color: $--point-list-bg-color;
}

::v-deep(.el-tabs__header) {
  box-sizing: border-box;
  padding: 0px 10px;
}

.border-panel {
  box-sizing: border-box;
  padding: 5px;
  // width: 260px;
  // height: 150px;
  display: flex;
  flex-shrink: 1;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.mode-btn-group {
  >div {
    width: 100%;

    >label {
      width: 50%;
    }
  }
}

::v-deep(.el-radio-button__inner) {
  width: 100%;
  font-size: large;
  font-weight: bold;
}

.fixture-active {
  z-index: 15;
}

.io-run-panel {
  @extend .border-panel;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  min-height: 104px;

  >button {
    width: 100%;
  }
}

.io-switch-list-wrap {
  border: 1px solid var(--el-color-primary-light-9);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.io-switch-wrap {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;

}

.robot-connect-header-wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    color: white;
  }
}

.connect-cfg-wrap {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.main-btn-wrap {
  flex-grow: 1;
}

.robot-warnning-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;

  >div {
    color: #393B83;
  }

  >div:first-child {
    color: red;
    display: flex;
    align-items: center;
    font-size: 24px;
  }
}

::v-deep(.el-tabs__content) {
  overflow: auto;
  height: 100%;
  box-sizing: border-box;
  padding: 8px;
}

::v-deep(.el-tabs__header) {
  margin: 0;
}

.fixture-setting-active {
  position: absolute;
  z-index: 10;
}

.log-wrap {
  background-color: rgba(255, 255, 255, 0.5);
  height: 25vh;
  width: 25vw;
  position: fixed;
  overflow: auto;
  top: 10px;
  left: 10px;
  z-index: 9999999;
  pointer-events: none;
}

.run-file-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  >div {
    flex: 1;
    display: flex;
    >div {
      flex: 1;
    }
    button {
      flex: 1;
    }
  }
}

.point-btn-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;

  >div {
    flex: 1;

    >button {
      width: 100%;
    }
  }
}

.auxiliary-button {
  position: fixed;
  bottom: 10px;
  right: 10px;
}

::v-deep(.el-tabs__item) {
  font-size: 14px;
}

.run-code-btn {
  border-right: none !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.change-speed-btn {
  border-left: none !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 5px;
}
