<template>
    <svg :class="svgClass" aria-hidden="true" v-on="$listeners" :style="{'width': size, 'height': size}">
        <use :xlink:href="iconName" />
    </svg>
</template>

<script setup lang="ts">
    import { defineProps } from 'vue'
    const props = defineProps({
        iconClass: {
            type: String,
            required: true
        },
        className: {
            type: String,
            default: ''
        },
        size: {
            type:String,
            default: '1em'
        }
    })

    const iconName = computed(()=>`#icon-${props.iconClass}`)
    const svgClass = computed(()=>{
        if (props.className) {
            return 'svg-icon ' + props.className
        } else {
            return 'svg-icon'
        }
    })
</script>

<style scoped>
    .svg-icon {
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
    }
</style>