$--point-list-bg-color: #F4F4F4;

.main-container {
  opacity: 1;
  width: 100%;
  height: 100%;
  background: url("~@/assets/bg.png") no-repeat center scroll;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10%;
}

#header {
  display: flex;
  justify-content: space-between;
  #header-btn-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;

    button {
      padding: 0px 2px;
      border: 0px;;
    }
  }
}

.sms-code-wrap {
  display: flex;
  gap: 5px;
  > div {
    width: 93px;
  }
  .sms-btn {
    width: 100px;
    flex-shrink: 0;
  }
}
.youtoo-box {
  // position: absolute;
  // top: 114px;
  // left: 144px;

  .logo {
    background-image: url("~@/assets/icons/logo.png");
    background-repeat: no-repeat;
    background-size: 100%;
    width: 150px;
    height: 48px;
    border-radius: 5px;
    left: 0;
    top: 0;
    margin: 10px;
  }

  .slogan {
    font-size: 38px;
    font-weight: bolder;
    color: white;
    margin-top: 50px;
  }
}

.form-box {
  background-color: white;
  border-radius: 17px;
  box-sizing: border-box;
  padding: 20px;
  // position: fixed;
  // right: 160px;
  // top: 114px;
}

.form-btn-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-title {
  font-size: 28px;
  font-weight: bolder;
}

#forget-pwd {
  margin-top: 20px;
  margin-left: 0;
}
::v-deep(.el-form-item__label) {
  display: inline-block;
  position: relative;
  text-align-last: justify;
}
::v-deep(.el-form-item__label::after) {
  display: inline-block;
  position: absolute;
  width: 0;
  flex-grow: 0;
  overflow: visible;
}
