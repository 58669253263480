$--point-list-bg-color: #F4F4F4;

.image {
  padding: 20px;
  mask-image: url("~@/assets/Logo.png");
  background: #1c1e63;
  mask-size: 40px;
  mask-position: top;
  mask-repeat: no-repeat;
}

.top-bar {
  // background-color: #ECEDFF;
}

.left-wrap {
  display: flex;
  align-items: center;
}

.right-wrap {
  display: flex;
  align-items: center;
  gap: 5px;
}

.auto-save-tips {
  font-size: 12px;
  font-weight: 300;
  vertical-align: middle;
  margin-left: 3px;
  /*align-self: flex-end;*/
}

.user-info-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  .username {
    width: 56px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    line-height: 32px;
    padding: 2px;
    box-sizing: border-box;
  }

}

::v-deep(.el-breadcrumb) {
  display: flex;
}
