<template>
  <div class="machine-item-wrap">
    <sensitive-input v-model:model="machine.name" style="width: 120px" :disabled="isReadOnly" :maxlength="15"/>
    <el-text type="info">{{ machine.type == ENTITY_TYPE.ROBOT ? `${machine.model.name} ${machine.model.brand}` : '' }}
    </el-text>
    <div style="flex:1"></div>
    <delete-btn :title="`确定删除'${machine.name}'吗？`" @del-confirm="onClickDel" v-if="isShowDelBtn" >
      <el-button :disabled="isReadOnly" style="width: 30px; max-width: 50px;flex: auto">删除</el-button>
    </delete-btn>
  </div>
</template>

<script setup lang='ts'>
  import {Machine,ENTITY_TYPE} from "@/model";
  import {defineProps, defineEmits} from 'vue'
  import SensitiveInput from '@/components/SensitiveInput'
  import DeleteBtn from '@/components/DeleteBtn'

  const props = defineProps({
    machine: {
      type: Machine,
      required: true
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    isShowDelBtn: {
      type: Boolean,
      default: true,
    }
  })
  const emit = defineEmits(['delConfirm'])
  function onClickDel() {
    emit('delConfirm')
  }
  function onZAngleChangeCallback(index: any, val: number, key) {
    props.machine.pr[key.toLowerCase()] = val
  }
</script>

<style scoped>
.machine-item-wrap {
  display: flex;
  gap: 5px;
}
</style>