$--point-list-bg-color: #F4F4F4;

.filter-input {
  padding-left: 0px;
}

::v-deep(.el-drawer__header) {
  margin-bottom: 0;
}

.tree-node-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-wrap {
  display: inline-flex;
  gap: 5px;
  box-sizing: border-box;
  align-items: center;
  border-radius: 24px;
  padding: 5px;
  border: 0.5px solid var(--el-color-primary-light-9);
  $--icon-height: 20px;
  $--icon-width: 20px;
  width: $--icon-width + 10px;
  transition: width 200ms;

  svg {
    width: $--icon-width;
    height: $--icon-height;
    display: inline-block;
    flex-shrink: 0;
  }

  .el-input {
    height: $--icon-height;
  }

  ::v-deep(.el-input__wrapper) {
    background: none !important;
    box-shadow: none !important;
  }

  ::v-deep(.el-input__inner) {
    background: none !important;
  }
}

.drawer-content-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.project-list-wrap {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 0px;
  margin-top: 10px;
  gap: 5px;
  overflow: auto;
}

.project-item {
  height: 50px;
  flex: 0 0 auto;
  box-sizing: border-box;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    border-radius: 8px;
    background-color: var(--el-color-primary-light-9);
  }
}

::v-deep(.el-divider__text) {
  font-size: 18px;
}

.right-menu {
  margin-right: 5px;
  visibility: visible;
}

::v-deep(.el-dropdown) {
  div {
    width: 100%;
    user-select: none;
    font-size: 16px;
  }
}
.project-name{
  line-height: 22px;
}
