$--point-list-bg-color: #F4F4F4;

.sence-desktop-wrap {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.main-scene {
  height: 100%;
  width: 100%;
}

.tree-scene-wrap {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

$--panel-width: 310px;

.planing-setting {
  will-change: width;
  flex-shrink: 0;
  width: $--panel-width;
  text-align: left;
  flex-direction: column;
  padding: 5px;
  position: relative;
  transition: all 0.2s;
}

.close-panel {
  @extend .planing-setting;
  margin: 0px;
  padding: 0px;
  width: 0px
}

.basic-panel-wrap {
  box-sizing: border-box;
  height: 100%;
  width: $--panel-width;
  overflow-y: auto;
}
