$--point-list-bg-color: #F4F4F4;

.position {
  display: flex;
  gap: 5px;
  align-items: center;
}

.card {
  display: flex;
  flex-direction: column;
  gap: 5px;

  div {
    span {
      width: 15px;
    }
  }
}
.coord-wrap {
  display: flex;
  font-size: 14px;
  align-items: center;
  gap: 5px;
  > div:first-child {
    width: 80px;
    flex-shrink: 0;
  }
}
