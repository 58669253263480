$--point-list-bg-color: #F4F4F4;

  .del-icon {
    width: 18px;
    height: 18px;
    line-height: 18px;
    cursor: pointer;
  }
  .un-visibility {
    visibility: hidden;
  }
  .del-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }
