<template>
  <div class="list-wrap">
    <div v-if="numerics.length != 0">
      <span>数字信号</span>
      <div class="panle">
        <div v-for="item in numerics" :key="item.id"
             style="display:flex; gap:3px; align-items: center; justify-content: center">
          <numerics-item :item="item" @on-change="(name, remark)=>handleIoChange(name, remark, item)" :handleDelIO="handleDelIO"/>
        </div>
      </div>
    </div>
    <div v-if="analogs.length != 0">
      <span>模拟信号</span>
      <div class="panle">
        <div v-for="item in analogs" :key="item.id"
             style="display:flex; gap:3px; align-items: center; justify-content: center">
          <numerics-item :item="item" @on-change="(name, remark)=>handleIoChange(name, remark, item)" :handleDelIO="handleDelIO"/>
        </div>
      </div>
    </div>
    <div v-if="decimals.length != 0">
      <span>十进制信号</span>
      <div class="panle">
        <template v-for="item in decimals" :key="item.id">
          <div class="decimal-item">
            <div style="display: flex; text-align: center;gap: 5px; align-items: center">
              <sensitive-input v-model:model="item.name" class="name-label"/>
              <span class="out-in-type-label">{{item.type === IO_TYPE.GI ? '输入': '输出'}}</span>
              <sensitive-input v-model:model="item.remark" placeholder="请输入注释" class="remark-input"/>
              <delete-item title="确定删除这个I/O吗?" @delConfirm="handleDelIO(item)"/>
            </div>
            <el-select
                v-model="item.using"
                multiple
                filterable
                remote
                reserve-keyword
                placeholder="请选择或添加使用的IO口"
                :remote-method="(query) => remoteMethod(query,item)"
                @click="remoteMethod('', item)"
                :multiple-limit="8"
                ref="decSelectRef"
                @input="onDecInput"
            >
              <el-option
                  v-for="item in newNameOptions"
                  :key="item.label"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import {IO_TYPE, Project, Robot, RobotIO} from "@/model";
  import {defineExpose} from 'vue'
  import {useWork} from "@/store/modules/work";
  import DeleteItem from '@/components/DeleteBtn/index.vue'
  import SensitiveInput from '@/components/SensitiveInput/index.vue'
  import NumericsItem from './NumericsItem.vue'

  const newIOSelect = ref([])
  const IN_OUT = [
    {
    value: '输入',
    label: '输入',
  }, {
    value: '输出',
    label: '输出',
  }]
  const NEW_IO_OPTIONS = [
    {
      value: '数字信号',
      label: '数字信号',
      options: IN_OUT
    },
    // {
    //   value: '模拟信号',
    //   label: '模拟信号',
    //   options: IN_OUT
    // },
    // {
    //   value: '十进制信号',
    //   label: '十进制信号',
    //   options: [{
    //     label: '输出',
    //     value: '输出'
    //   }]
    // },

  ]


  const store = useWork()
  const curRobot = computed(() => Project.current()?.curProgram?.robot)
  const numerics = computed(() => ioList.value.filter(e => (e.type == IO_TYPE.DI || e.type == IO_TYPE.DO)))
  const analogs = computed(() => ioList.value.filter(e => (e.type == IO_TYPE.AI || e.type == IO_TYPE.AO)))
  const decimals = computed(() => ioList.value.filter(e => (e.type == IO_TYPE.GI || e.type == IO_TYPE.GO)))
  const ioList = computed(() => {
    if (!curRobot.value) {
      return []
    }
    let feedBinIos:string[] = []
    curRobot.value.paths.forEach(path => {
      if (path.ioId) {
        feedBinIos.push(path.ioId)
      }
    })
    return curRobot.value.code.ioList.filter(io => !feedBinIos.includes(io.id))
  })
  const decSelectRef = ref()
  const newNameOptions = ref([]) as any

  function remoteMethod(query: string, item: any) {
    if (!curRobot.value) return
    query = query.trim()
    let forceName = query != '' && !isNaN(Number(query)) ? Number(query) : 1
    let res: Array<any> = []
    let names = curRobot.value.code.usingNames(IO_TYPE.GO)
    for (let i = 0; i < 9 - item.using.length && item.using.length<8; i++) {
      let newName = forceName
      while (names.find(e => e == `#${newName}`) || res.find(e => e.label == `#${newName}`)) {
        newName++
      }
      res.push({
        label: `#${newName}`,
        value: `#${newName}`
      })
    }
    newNameOptions.value = res
  }


  function onDecInput(value) {
    if (decSelectRef.value) {
      decSelectRef.value.forEach(e=>e.input.value = e.input.value.replace(/\D/g, ""))
    }
  }

  function handleNewIOVar(command: string | number | object) {
    if (!curRobot.value) return
    let ioVar: any = {}
    switch (command[0]) {
      case NEW_IO_OPTIONS[0].value:
        ioVar.type = command[1] === IN_OUT[0].value ? IO_TYPE.DI : IO_TYPE.DO
        ioVar.dict = [{val: 1, text: ''}, {val: 0, text: ''}]
        break;
      case NEW_IO_OPTIONS[1].value:
        ioVar.type = command[1] === IN_OUT[0].value ? IO_TYPE.AI : IO_TYPE.AO
        break;
      case NEW_IO_OPTIONS[2].value:
        ioVar.type = command[1] === IN_OUT[0].value ? IO_TYPE.GI : IO_TYPE.GO
        ioVar.using = []
        break;
    }
    curRobot.value.code.addIO(ioVar.name, ioVar.type, 0, '', ioVar.using, ioVar.dict)
  }
  defineExpose({
    handleNewIOVar,
    NEW_IO_OPTIONS
  })

  function handleIoChange(name:string, remark:string, item) {
    item.name = name
    item.remark = remark
    store.notifyChangeIO(item)
  }

  function handleDelIO(item: RobotIO) {
    if (!curRobot.value) return

    store.notifyDeleteIO(item)
    curRobot.value.code.delIO(item.id)
  }
</script>

<style scoped>
  .panle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /*border: 1px dashed var(--el-color-primary-light-9);*/
    border-radius: 5px;
    text-align: center;
    padding: 5px;
    row-gap: 5px;
  }

  .list-wrap {
    overflow: scroll;
    font-size: 14px;
  }

  .item-background__selected {
    background-color: #ecedff;
  }

  .name-label {
    width: 100px;
  }

  .out-in-type-label {
    width: 50px;
  }

  .remark-input {
    /*width: 100px;*/
  }

  .decimal-item {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 5px;
    padding: 2px;
    border: 1px dashed var(--el-color-primary-light-9);
  }
</style>
