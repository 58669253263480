$--point-list-bg-color: #F4F4F4;

$--tree-item-height: 40px;
$--saved-color: #00B900;
$--unsave-color: #FDCA40;
$--group-color: #393B83;

.is-active {
  background-color: var(--el-color-primary-light-9) !important;
  z-index: 333;
}

.tree-item {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background-color: white;

  .item-start {
    width: 10px;
    height: 100%;
  }

  .item-name {
    // margin-left: 10px;
  }

  .icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: 12px;
    gap: 10px;
  }

  .state-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .add-point-btn {
    margin-left: auto;
    margin-right: 25px;
    margin-left: auto;
  }

  .bold {
    font-weight: bold;
  }
}

::v-deep(.el-tree) {
  background: none;
}
::v-deep(.el-tree-node) {
  min-height: $--tree-item-height;
  background-color: $--point-list-bg-color;
  &:focus {
    background: rgba(255,255,255,0);
  }
  .el-tree-node__children {
    overflow: visible;
    margin-top: 10px;
  }
  .el-tree-node__content {
    height: $--tree-item-height;

    .el-tree-node__expand-icon {
      position: absolute;
      right: 0;
      transform: rotate(180deg);
    }

    .el-tree-node__expand-icon.expanded {
      transform: rotate(90deg) !important;
    }
  }
  .el-tree-node__content:hover {
    background-color:rgba(255,255,255,0);
  }
  &:focus>.el-tree-node__content {
    background-color:rgba(255,255,255,0);
  }
}

::v-deep(.el-tree-node+.el-tree-node){
  margin-top: 10px;
}
.io-input {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  flex-shrink: 0;
  flex-grow: 0;
  margin-left: 5px;
  gap: 5px;
}

.not-point {
  background-color: $--group-color;
}

.is-save {
  background-color: $--saved-color !important;
}

.is-unsave {
  background-color: $--unsave-color !important;
}
$--btn-size: 15px;
.add-btn {
  width: $--btn-size;
  height: $--btn-size;
  position: absolute;
}
.up-add-btn {
  @extend .add-btn;
  top: -2 * $--btn-size;
}
.down-add-btn {
  @extend .add-btn;
  top: $--btn-size;
}
.add-btn-wrap {
  position: relative;
  overflow: visible;
  width: 100%;
  text-align: center;
  .el-button+.el-button {
    margin-left: 0;
  }
}
.path-icon {
  font-size: 34px;
  flex-shrink: 0;
}
