$--point-list-bg-color: #F4F4F4;

.brand-wrap {
  display: flex;
  align-items: center;
  gap: 3px;
  margin-top: 5px;

  >span {
    flex: 1;
  }

  >span:first-child {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 50px;
  }
}

.cancel-btn {
  margin-top: 20px;
}

.loading-wrap {
  margin-top: 13px;
}

.robot-connecting-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 24px;
  color: #393B83;
}

.ip-port-wrap {
  margin-top: 3px;
  display: flex;
  gap: 3px;
  align-items: center;

  >span:first-child {
    width: 50px;
    flex-shrink: 0;
  }
}

.loading-wrap {
  @extend .loading;
}

.loading {
  position: relative;

  animation: circle infinite 0.75s linear;
}

@keyframes circle {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.dashed {
  margin-top: 3px;
  border-top: 1px dashed var(--el-border-color);
}

.ip-input {
  width: 100px;
}

.port-input {
  width: 100px;
}

.confirm-btn {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep(.el-overlay) {
  position: absolute;
  z-index: 10!important;
}

::v-deep(.el-overlay-dialog) {
  position: absolute;
}

::v-deep(.el-dialog__body) {
  padding: 0px calc(var(--el-dialog-padding-primary) + 5px) 30px
}