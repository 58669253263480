<template>
  <div style="display: flex">
    <!-- <el-button @click="toggleToolbox">点击我</el-button> -->
    <div class="blocklyDiv" ref="blocklyDiv"></div>
    <div style="display: flex; flex-direction: column;">
      <!-- <div class="minimapDiv" ref="minimapDiv"></div> -->
      <!-- <div class="outputPane"> -->
      <!-- <pre class="generatedCode"><code ref="codeDiv"></code></pre> -->
      <!--        <div class="output" ref="output"></div>-->
      <!-- </div> -->
    </div>
    <xml ref="blocklyToolbox" style="display: none">
      <slot></slot>
    </xml>
  </div>
</template>

<script lang="ts" setup>
/**
 * @author surui.cc@gmail.com
 * @desc Blockly Vue Component.
 */

import { defineExpose, defineProps, onMounted, ref, shallowRef, onBeforeUnmount } from "vue";
import { ElNotification } from 'element-plus'
import { Blockly } from './patch/blockly';
import { Mini } from './core/minimap';
import { tmpPool } from "@/utils/ast";

let Zh = require('blockly/msg/zh-hans');
import * as opts from "./config/options.json";
import { robotScript } from './generators/robot_script';
import './blocks'
import { Entity, ENTITY_TYPE, Project, Robot, RobotProgram, RobotVar, VAL_TYPE } from "@/model";
import { Abstract } from "blockly/core/events/events_abstract";
import { useWork } from "@/store/modules/work";
import { BlockBase } from "blockly/core/events/events_block_base";
import { Utils } from "@/utils";
import { BlockDelete } from "blockly/core/events/events_block_delete";
import { oneMessage } from "@/utils/one_message";
import { BlockDrag } from "blockly/core/events/events_block_drag";
import { BlockSvg } from "blockly/core/blockly";

const props = defineProps(["options"]);
const blocklyDiv = ref();
// const minimapDiv = ref();
// const codeDiv = ref();
const workspace = shallowRef<Blockly.Workspace>();
const miniWorkspace = shallowRef<Blockly.Workspace>();

let isShowToolbox = true;
const toggleToolbox = ()=>{
  if (!workspace.value) return;
  let wp = workspace.value as Blockly.WorkspaceSvg
  let toolbox = wp.getToolbox() as Blockly.Toolbox
  if (toolbox) {
    if (isShowToolbox) {
      isShowToolbox = false;
      toolbox.clearSelection();
      let categories = toolbox.getToolboxItems();
      for (let i = 0; i < categories.length; i++) {
        let category = categories[i];
        category.setVisible_(false);
      }
    } else {
      isShowToolbox = true
      toolbox.clearSelection();
      let categories = toolbox.getToolboxItems();
      for (let i = 0; i < categories.length; i++) {
        let category = categories[i];
        category.setVisible_(true);
      }
    }
  }
}
/**
 * @param id 变量ID
 * @param name 变量名称
 * @param type 变量类型
 */
function createVar(id: string, name: string, type: string) {
  if (workspace.value!.getVariableById(id)) {
    workspace.value!.renameVariableById(id, name)
  } else {
    workspace.value!.createVariable(name, type, id)
  }
}

/**
 * 删除Blockly变量
 * @param id 变量ID
 */
function deleteVar(id: string) {
  workspace.value!.deleteVariableById(id)
}

defineExpose({
  workspace,
  miniWorkspace,
  createVar,
  deleteVar
});
let oldProjectId
onMounted(() => {
  const options = props.options || {};
  options.theme = opts.theme
  if (!options.toolbox) {
    options.toolbox = opts.toolbox
  }
  Blockly.setLocale(Object.assign(Zh, opts.msg["zh-hans"]));
  Blockly.Msg.LOGIC_BOOLEAN_TRUE = '真'; // 修改true的消息
  Blockly.Msg.LOGIC_BOOLEAN_FALSE = '假'; // 修改false的消息
  workspace.value = Blockly.inject(blocklyDiv.value, Object.assign(options, {
    renderer: 'thrasos',
    media: '/static/media/',
    sounds: false, // 禁用点击音效
  }));
  // Inject workspace for minimap.
  // miniWorkspace.value = Blockly.inject(minimapDiv.value, Object.assign(options, {
  //   media: '/static/media/',
  //   readOnly: true,
  //   renderer: 'thrasos',
  //   theme: options.theme,
  //   sounds: false,
  //   zoom: {
  //     controls: false,
  //     wheel: true,
  //     startScale: 0.1, // Change this according to your needs.
  //     maxScale: 1,
  //     minScale: 0.1,
  //   }
  // }));
  // Mini.init(workspace.value, miniWorkspace.value, minimapDiv.value)


  const runCode = () => {
    let project = Project.current()
    // 清理临时变量
    tmpPool.clear();
    if (project?.curProgram.program?.localVars) {
      project?.curProgram.program?.localVars.forEach(item => {
        if (item.name.indexOf("临时变量") != -1 || item.name.indexOf("$temp") != -1) {
          project?.curProgram.program?.delLocalVar(item.id)
        }
      })
    }
    try{
      const code = robotScript.workspaceToCode(workspace.value);
      // if (codeDiv.value) {
      //   codeDiv.value.innerText = code;
      // }
      if (project) {
        let array = code.split('\n').filter(c => c.startsWith('[')).map(c => c.replace(/,$/, '')).join(',\n')
        let actions = JSON.parse(`[${array}]`).filter(a => a.length)
        project.curProgram.program!.actions = actions
      }
    } catch(e) {
      ElNotification({
        title: '程序错误',
        message: `请检查程序指令块！（${(e as Error).message}）`,
        type: 'error',
      })
      console.error(e)
    }
  }

  function getBlockVars(block): RobotVar[] {
    let Vars: RobotVar[] = []
    // if (block.type == 'vars_def') {
    let blocks = workspace.value!.getBlocksByType('vars_def', false)
    blocks.forEach(block => {
      let name: string = block.getFieldValue("VAR")
      let type: string = block.getFieldValue("TYPE")
      if (type == VAL_TYPE.POSE) {
        let x = block.getFieldValue("INIT_X") as number
        let y = block.getFieldValue("INIT_Y") as number
        let z = block.getFieldValue("INIT_Z") as number
        let rx = block.getFieldValue("INIT_RX") as number
        let ry = block.getFieldValue("INIT_RY") as number
        let rz = block.getFieldValue("INIT_RZ") as number
        Vars.push({ id: block.id, name, type: type as VAL_TYPE, initVal: [x, y, z, rx, ry, rz], isStatic: false })
      } else if (type == VAL_TYPE.JOINT) {
        let initVal = Project.current()!.curProgram.robot.model.z_axes.map(a => 0)
        initVal.length = Math.max(0, initVal.length - 1)
        Vars.push({ id: block.id, name, type: type as VAL_TYPE, initVal, isStatic: false })
      } else {
        let initVal: string = block.getFieldValue("INIT_VALUE")
        Vars.push({ id: block.id, name, type: type as VAL_TYPE, initVal, isStatic: false })
      }
    })
    // } else if (block.type == 'timer_def') {
    blocks = workspace.value!.getBlocksByType('timer_def', false)
    blocks.forEach(block => {
      let name: string = block.getFieldValue("VAR")
      let type: string = VAL_TYPE.DOUBLE
      let initVal: number = 0
      // console.log(name, type)
      Vars.push({ id: block.id, name, type: type as VAL_TYPE, initVal, isStatic: false })
    })
    // } else if (block.type == 'tag_mark') {
    blocks = workspace.value!.getBlocksByType('tag_mark', false)
    blocks.forEach(block => {
      let name: string = block.getFieldValue("TAG")
      let type: string = VAL_TYPE.LABEL
      let initVal: number = 0
      // console.log(name, type)
      Vars.push({ id: block.id, name, type: type as VAL_TYPE, initVal, isStatic: false })
    })
    // }
    return Vars
  }

  function onEndDrag(block, done: Function) {
    let onDrag = (e: BlockBase) => {
      if (e.type === Blockly.Events.MOVE && e.blockId === block.id) {
        workspace.value!.removeChangeListener(onDrag)
        done()
      }
    }
    workspace.value!.addChangeListener(onDrag)
  }

  workspace.value.addChangeListener((evt) => {
    let e = evt as unknown as BlockBase
    if (!e.blockId) return
    let block = workspace.value!.getBlockById(e.blockId)
    // 给块添加监听
    if (e.type == Blockly.Events.CLICK) {
      let blockType = workspace.value!.getBlockById(e.blockId)?.type
    }
    // 删除
    else if (e.type == Blockly.Events.BLOCK_DELETE) {
      let project = Project.current()!
      let robot = project.curProgram.robot!
      let pg = project.curProgram.program!
      let d = e as BlockDelete
      let block = d.oldJson!
      const delVar = (block) => {
        if ((block.type == 'vars_def' || block.type == 'timer_def' || block.type == 'tag_mark') && block.id) {
          console.log(block)
          if (pg.isMain) {
            robot.code.delGlobalVar(block.id)
          } else {
            RobotProgram.prototype.delLocalVar.call(pg, block.id)
          }
        }
        if(block.next) delVar(block.next.block)
      }
      delVar(block)
    }
    // 增改
    else if (e.type == Blockly.Events.BLOCK_CREATE || e.type == Blockly.Events.BLOCK_CHANGE) {
      let project = Project.current()!
      let robot = project.curProgram.robot!
      let pg = project.curProgram.program!
      let block = workspace.value!.getBlockById(e.blockId)!
      if (block.isCollapsed()) {
        block.setCollapsed(false)
        nextTick(()=> block.setCollapsed(true))
      }
      if (e.type == Blockly.Events.BLOCK_CREATE) {
        if (block.type == 'vars_def') {
          if (block.getFieldValue('VAR') == "变量名") {
            onEndDrag(block, () => {
              let index = project.getNewId('var', '')
              block.setFieldValue(`变量${index}`, 'VAR')
            });
          }
        } else if (block.type == 'timer_def') {
          if (block.getFieldValue('VAR') == "秒表名称") {
            onEndDrag(block, () => {
              let index = project.getNewId('timer', '')
              block.setFieldValue(`秒表${index}`, 'VAR')
            });
          }
        } else if (block.type == 'tag_mark') {
          if (block.getFieldValue('TAG') == "书签名称") {
            onEndDrag(block, () => {
              let index = project.getNewId('tag', '')
              block.setFieldValue(`书签${index}`, 'TAG')
            });
          }
        }
        let blocks = workspace.value!.getBlocksByType('move_material', false)

        if (blocks?.length > 1) {
          nextTick(() => {
            workspace.value!.removeBlockById(block.id)
            block.dispose(true)
            oneMessage({
              message: '只能引入一个自动取料指令',
              type: 'warning'
            })
          })
        }
      }
      let Vars = getBlockVars(block);
      if (Vars.length) {
        if (pg.isMain) {
          robot.code.globalVars.length = 0
          // Vars = Vars.filter(Var => !robot.code.globalVars.find(v => v.name == Var.name))
          Vars.forEach(Var => {
            // console.log(Var)
            robot.code.addGlobalVar(Var)
          })
        } else {
          pg.localVars.length = 0
          // Vars = Vars.filter(Var => !pg.localVars.find(v => v.name == Var.name))
          Vars.forEach(Var => {
            // console.log(Var)
            RobotProgram.prototype.addLocalVar.call(pg, Var)
          })
        }
      }
    }
    if (e.isUiEvent || e.type === Blockly.Events.FINISHED_LOADING || workspace.value!['isDragging']()) {
      if (e.type === 'drag' || e.type === 'click') {
        if (e.type === 'drag' && (e as BlockDrag).isStart) return
        let rootBlock = workspace.value?.getBlockById(e.blockId) as BlockSvg
        // TODO：检测区块重合
        // 获取最大的width
        if(!rootBlock) return
        let allBlocks = workspace.value?.getAllBlocks() as BlockSvg[];
        let startBlocks = allBlocks?.filter(block=>!block["parentBlock_"] && block.id !== e.blockId)
        let getRect = (block: BlockSvg) =>{
          let startXY = block.getRelativeToSurfaceXY()
          let endXY = {x:startXY.x + block.width, y:startXY.y + block.height}

          let searchAllBlock = (block:BlockSvg)=>{
            let position = block.getRelativeToSurfaceXY()
            endXY.x = Math.max(position.x + block.width, endXY.x)
            endXY.y = Math.max(position.y + block.height, endXY.y)
            if (block['childBlocks_'].length > 0) {
              block['childBlocks_'].forEach(item=>searchAllBlock(item))
            }
          }

          searchAllBlock(block)

          return {startXY, endXY}
        }
        let {startXY:dragStartXY, endXY:dragEndXY} = getRect(rootBlock)
        // 检测区块是否重叠
        startBlocks?.forEach((block)=>{
          // 计算它的矩形坐标
          let {startXY, endXY} = getRect(block)
          if (dragStartXY.x < startXY.x
            && dragStartXY.y < startXY.y
            && dragEndXY.x > endXY.x
            && dragEndXY.y > endXY.y) {
              // move root块
              block.moveBy(dragEndXY.x - startXY.x, 0, ["blocks cover"])
            }
        })
      }
      return true;
    } else {
      if (oldProjectId === Project.current()?.id) {
        let code = Blockly.serialization.workspaces.save(workspace.value!)
        let program = Project.current()!.curProgram.program
        if (program) program.code = code
      }
      oldProjectId = Project.current()?.id
    }
    runCode()
  })
  const work = useWork()
  work.$onAction(({ name, store, args, after, onError }) => {
    if (name === 'notifyChangeProgram') {
      after((resolve) => {
        let state = Project.current()!.curProgram.program?.code
        if (!workspace.value) throw new Error("workspace is undefined!")
        if (state) Blockly.serialization.workspaces.load(state, workspace.value)
      })
    } else if (name === 'notifyChangeProject') {
      // nothing
    } else if (name.startsWith('notifyChange')) {
      runCode()
    }
  })
});
onBeforeUnmount(() => {
  workspace.value?.dispose()
  miniWorkspace.value?.dispose()
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.blocklyDiv {
  height: 100%;
  width: 100%;
  text-align: left;
}

.minimapDiv {
  width: 100px;
  height: 100%;
}

pre,
code {
  overflow: auto;
}

.outputPane {
  display: flex;
  flex-direction: column;
  width: 100px;
  flex: 0 0 200px;
  overflow: auto;
  /*margin: 1rem;*/
}

.generatedCode {
  margin: 0px;
  height: 100%;
  background-color: rgb(247, 240, 228);
}

.output {
  height: 50%;
}
</style>
<style>
.minimap {
  position: absolute;
}

.mapDragger {
  cursor: move;
  fill: rgb(0, 0, 255);
  stroke-width: .5;
  stroke: rgb(0, 0, 0);
  fill-opacity: .1;
}
</style>
