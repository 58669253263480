$--point-list-bg-color: #F4F4F4;

  //--------------------------------

  .myInputLock .el-input__inner {
    height: 25px;
    background: #8E90D7;
    color: #ffffff;

    &::placeholder {
      color: white;
    }
  }

  .myInputLock .el-input__inner:focus {
    height: 25px;
    background: #ffffff;
    color: #393b83;
  }

  .myInputOn .el-input__inner {
    height: 25px;
    background: #ffffff;
    color: #393b83;

    &::placeholder {
      color: #393b83;
    }
  }

  .myInputLock .el-input--suffix {
    .el-input__inner {
      height: 25px;
      background: #ffffff;
      color: #8E90D7;
    }

    &::placeholder {
      color: black;
    }
  }

  //------------------------------
