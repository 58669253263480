$--point-list-bg-color: #F4F4F4;

#tips {
  font-size: 22px;
  padding-bottom: 20px;
}
#device {
  font-weight: bold;
}
.el-radio-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
