$--point-list-bg-color: #F4F4F4;

$io-input-width: 40px;
$io-switch-width: 55px;

.update-info {
  font-size:12px;
  margin-left: 5px;
}

.io-item {
  display: flex;
}

.io-list {
  padding: 0px 5px;
  margin-top: 5px;
}

.content-title-wrap {
  padding: 0px 5px;
  display: flex;
  border-bottom: 1px solid #7A7CBF;
  gap: 5px;

  >span:first-child {
    width: $io-input-width;
  }

  .remark-title {
    flex: 1;
  }

  >span:last-child {
    width: $io-switch-width;
    text-align: center;
  }
}

.io-list-wrap {
  padding: 3px;
  display: flex;
  flex-direction: column;
  // gap: 5px;
}

.io-item-wrap {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 5px;

  span {
    flex-shrink: 0;
  }

  >div {
    width: $io-input-width;
  }

  .remark-content {
    flex: 1;
  }

  >div:last-child {
    width: $io-switch-width;
    text-align: center;
  }
}
.io-state-img {
  width: 15px;
  height: 15px;
}
.io-state-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.io-item-wrap {
  height: 40px;
}
.update-wrap{
  margin-bottom: 20px;
}
