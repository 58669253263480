$--point-list-bg-color: #F4F4F4;


.panel-title-wrap{
  display:flex;
  gap: 5px;
  align-items: center;
  .panel-title {
    width: 60px;
    text-align: left;
  }
}
  .program-panel-wrap {
    box-sizing: border-box;
    width: 290px;
    padding: 3px;
    flex-direction: column;
    flex-shrink: 0;
    gap: 10px;
    display: flex;
    overflow: auto;
    height: 100%;
    > .el-card {
      flex-shrink: 0;
    }
  }

  .label {
    width: 80px;
  }

  .button {
    display: flex;
    flex-direction: row-reverse;
    gap: 5px;
    margin-top: 5px;
    align-items: center;
    justify-content: flex-start;
  }


  .val-wrap {
    /*margin-top: 5px;*/
    overflow: auto;
    /*max-height: 350px;*/
  }

  .my-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .content-header {
    display: flex;
    gap: 5px;
  }
  .main-content {
    flex: 1;
    height: 0;
    overflow: auto;
  }
  .process-group-item {
    display: flex;
  }
  #process-group-add-wrap {
    margin-top: 15px;
    text-align: center;
  }

  .darg-icon {
    transform: rotate(90deg);
  }
  ::v-deep(.el-collapse-item__content) {
    padding-bottom: 5px;
  }

  ::v-deep(.el-card__body) {
    padding: 5px !important;
  }

  ::v-deep(.el-button+.el-button) {
    margin-left: 0px;
  }

  .dialog-footer {
    display: flex;
    flex-direction: row-reverse;
    gap: 5px;
  }
  .robot-brand-wrap {
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    > span {
      flex-shrink: 0;
    }
  }
#process-group-list {
  max-height: 200px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 1;
  >div {
    display: flex;
    flex-direction: column;
    gap: 3px;
    flex: 1;

    >div {
      display: flex;
      align-items: center;
      gap: 3px;
      flex: 1;
      >div{
        flex: 1;
      }
    }
  }
}
  ::v-deep(.el-card) {
    font-size: 14px;
  }

::v-deep(.el-tabs) {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 5px;
  height: 100%;
}
::v-deep(.el-tabs__content) {
  overflow: auto;
  height: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
::v-deep(.el-tab-pane) {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 5px;
}
