<template>
  <io-input v-model:model="nameModel" :item="item" class="name-label" @onChange="onChange"/>
  <span class="out-in-type-label">{{item.type === IO_TYPE.DI || item.type === IO_TYPE.AI ? '输入': '输出'}}</span>
  <sensitive-input v-model:model="remarkModel" placeholder="请输入注释" class="remark-input" @onChange="onChange" :isNullable="true"/>
  <!--        <el-popover trigger="click" :hide-on-click="false" width="150px">-->
  <!--          <template #reference>-->
  <!--            <el-icon style="margin-left: auto;height: 16px; width: 16px;cursor:pointer">-->
  <!--              <More/>-->
  <!--            </el-icon>-->
  <!--          </template>-->
  <!--          <template #default>-->
  <!--            <div>-->
  <!--              <span>{{item.dict[0].val}}</span>-->
  <!--              <el-input v-model="item.dict[0].text" style="width:100px; margin-left: 5px;" placeholder="别名"/>-->
  <!--            </div>-->
  <!--            <div style="margin-top: 5px">-->
  <!--              <span>{{item.dict[1].val}}</span>-->
  <!--              <el-input v-model="item.dict[1].text" style="width:100px; margin-left: 5px;" placeholder="别名"/>-->
  <!--            </div>-->
  <!--          </template>-->
  <!--        </el-popover>-->
  <delete-item title="确定删除这个I/O吗?" @delConfirm="handleDelIO(item)"/>
</template>

<script setup lang='ts'>
  import {IO_TYPE, RobotIO} from '@/model'
  import {defineProps, defineEmits, withDefaults} from 'vue'
  import SensitiveInput from '@/components/SensitiveInput/index.vue'
  import DeleteItem from '@/components/DeleteBtn/index.vue'
  import IoInput from '@/components/IoInput/index.vue'
  import {useWork} from "@/store/modules/work";

  const props = withDefaults(defineProps<{
    item: RobotIO,
    handleDelIO: Function,
    beforeChange: Function
  }>(), {beforeChange: (val) => true})
  const emit = defineEmits(['onChange', 'update:item.name'])
  const nameModel = computed({
    get: ()=>props.item.name,
    set: (value:string) => emit('onChange', value, remarkModel.value)
  })
  const remarkModel = computed({
    get:()=>props.item.remark,
    set: (value:string)=>emit('onChange', nameModel.value, value)
  })
  const store = useWork()

  function onChange () {
    // emit('onChange', nameModel.value, remarkModel.value)
  }
</script>

<style scoped>
  .out-in-type-label {
    width: 50px;
  }
  .name-label {
    width: 100px;
  }
</style>
