$--point-list-bg-color: #F4F4F4;

.center-panel-wrap {
  display: flex;
  flex-direction: column;

  * {
    user-select: none;
  }
}

.list-header-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.path-list-wrap {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.item-content-wrap {
  box-sizing: border-box;
  padding: 3px;
  width: 430px;
  white-space: nowrap;
  max-height: 500px;
  overflow: auto;
  position: relative;
}

.item-header-wrap {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-grow: 1;

  div {
    display: flex;
    align-items: center;
    gap: 3px;
    flex-grow: 1;

    .el-input {
      width: 40px;
      flex-grow: 0;
      flex-shrink: 0;
    }
  }
}

.feed-bin-list-wrap {
  display: flex;
  gap: 10px;
  overflow: auto;
  box-sizing: border-box;
  padding: 3px;
}

.feed-bin-wrap {
  display: flex;
  flex-grow: 1;
  gap: 5px;
  width: 0;
}

.program-path-wrap {
  box-sizing: border-box;
  padding: 3px;
  background-color: $--point-list-bg-color;

  >div+div {
    margin-top: 10px;
  }
}

.feed-bin-content {
  box-sizing: border-box;
  padding: 5px;
  background-color: $--point-list-bg-color;
  >div+div {
    margin-top: 10px;
  }
}
