$--point-list-bg-color: #F4F4F4;

.path-type-name {
  font-size: 12px;
  width: 50px;
  flex-shrink: 0;
}

.darg-icon {
  transform: rotate(90deg);
}

::v-deep(.el-checkbox__label) {
  padding-left: 3px;
}

.add-btn-wrap {
  text-align: right;
  margin: 5px;
}

.content-list-wrap {
  overflow: auto;
  box-sizing: border-box;
  padding: 2px;

  >div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3px;

    >div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 3px;
      .item-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3px;
      }
    }
  }

  >div+div {
    margin-top: 2px;
  }
}

::v-deep(.el-tabs__content) {
  overflow: auto;
  height: 100%;
  border: 1px solid var(--el-border-color-light);
  box-sizing: border-box;
  padding: 2px;
}

::v-deep(.el-tabs__header) {
  margin: 0;
}

::v-deep(.el-tabs__item) {
  padding-left: 10px !important;
  padding-right: 10px !important;
}