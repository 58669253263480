$--point-list-bg-color: #F4F4F4;

.slidecontainer {
  position: absolute;
  width: 50%;
  /* Width of the outside container */
  height: 120px;
  top: 900px;
}

.is-show-lock-limit {
  display: flex;
  padding: 0 90px 0 50px;
  justify-content: space-between;
}

.un-show-lock-limit {
  display: flex;
  padding: 0 90px 0 38px;
  justify-content: space-between;
}

.slider-wrapper {
  padding-left: 20px;
  padding-right: 20px;
  /*width: auto;*/
  text-align-last: center;
}

.el-slider__runway.show-input {
  margin-right: 15px;
}

.el-slider__input {
  width: 80px;
}

.mySlider .el-slider__bar {
  background-color: #8E90D7;
}

.mySlider .el-slider__button {
  border: 1px solid #393b83;
}

#AsideBtn {
  padding: 0px;
  border: 0px;
  width: 48px;
  height: 24px;
}

::v-deep(.el-input-number) {
  width: 74px
}

.axis-box {
  border: 0.1px solid #7a7cbf;
  background: #cccde7;
  padding: 2px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

::v-deep(.el-slider__runway.show-input) {
  margin-right: 15px;
}

::v-deep(.slider-wrapper) {
  padding-left: 15px;
}

.lock-checkbox {
  width: 20px;
}
