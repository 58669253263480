$--point-list-bg-color: #F4F4F4;

.top-bar {
  height: var(--header-height);
}

.status-bar {
  height: var(--status-bar-height);
}

.left-bar {
  height: 100%;
}

.main {
  height: 100%;
  width: 100%;
  display: flex;

  left-bar {
    height: 100%;
  }
}

.center {
  width: 100%;
}

.content {
  width: 100%;
  height: calc(100% - var(--header-height) - 2px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.view {
  width: 100%;
  height: 100%;
}

.game {
  height: 100%;
  width: 100%;
}

.right {
  width: 310px;
}

.log-wrap {
  background-color: rgba(255, 255, 255, 0.5);
  height: 20vh;
  width: 25vw;
  position: fixed;
  overflow: auto;
  bottom: 0;
  left: 0;
  z-index: 9999999;
  pointer-events: none
}

.err-wrap {
  background-color: rgba(255, 255, 255, 0.5);
  color: red;
  height: 50vh;
  width: 25vw;
  position: fixed;
  overflow: auto;
  bottom: 0;
  right: 25vw;
}
