$--point-list-bg-color: #F4F4F4;

.blockly {
  width: 100%;
  height: 100%;
}

.program-wrap {
  display: flex;
  width: 100%;
  height: 100%;
}

.blocklyFieldRect {
  fill: #fff !important;
  fill-opacity: 1 !important;
  margin: 5px !important;
}

.blocklyDropdownRect {
  fill: #fff !important;
  fill-opacity: 1 !important;
}

.blocklyEditableText {
  fill-opacity: 1 !important;

  text {
    fill: var(--el-color-editable-text) !important;
  }
}

.blocklyText {
  fill-opacity: 1 !important;
  fill: ghostwhite !important;
  font: normal 11pt sans-serif !important;
}

.blocklyTreeRow,
.blocklyText,
.blocklyFlyoutLabelText {
  user-select: none !important;
}

.blocklyBold {
  font: bold 12pt sans-serif !important;
}

.blocklyTag,
.blocklyDropdownText {
  fill: #7072DD !important;
  font: normal 11pt sans-serif !important;
  line-height: 15px !important;
}

.blocklyToolboxDiv>.blocklyToolboxContents>.blocklyToolboxCategory>.blocklyTreeRow>.blocklyTreeRowContentContainer {
  display: flex;
  flex-direction: row-reverse;
  /*min-width: 150px;*/
  align-items: center;

  height: 40px !important;
}

.blocklyTreeRow {
  height: 40px !important;
}

.blocklyTreeLabel {
  flex: auto;
}


.blocklyTreeIconOpen {
  -moz-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  transform: scaleY(-1);
  /*兼容IE*/
  filter: FlipV;
}
