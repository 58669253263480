$--point-list-bg-color: #F4F4F4;


.program-path-wrap {
  box-sizing: border-box;
  padding: 3px;
  background-color: $--point-list-bg-color;

  >div+div {
    margin-top: 10px;
  }
}

.path-list-wrap {
  display: flex;
  gap: 10px;
  flex-direction: column;
}
