<template>
  <div class="left-bar">
    <el-menu :default-active="activeIndex" class="el-menu-vertical" :collapse="isCollapse" @open="handleOpen"
      @close="handleClose" @select="handleSelect">
      <div class="logo">
        <div class="image"></div>
      </div>
      <!-- <el-menu-item index="0">
        <div class="enum-item el-menu-tooltip__trigger">
          <el-icon class="icon-item">
            <svg-icon class="icon" icon-class="project" />
          </el-icon>
          <span>项目</span>
        </div>
      </el-menu-item> -->
      <el-menu-item index="1" :disabled="!(userInfo?.perms || []).includes('app:ide:page:design')" :class="activeIndex == 1 ? 'menu-select' : ''">
        <div class="enum-item el-menu-tooltip__trigger">
          <el-icon class="icon-item">
            <svg-icon class="icon" icon-class="model" />
          </el-icon>
          <span>规划</span>
        </div>
      </el-menu-item>
      <el-menu-item index="2" :disabled="!(userInfo?.perms || []).includes('app:ide:page:program')" :class="activeIndex == 2 ? 'menu-select' : ''">
        <div class="enum-item el-menu-tooltip__trigger">
          <el-icon class="icon-item">
            <svg-icon class="icon" icon-class="block" />
          </el-icon>
          <span>编程</span>
        </div>
      </el-menu-item>
      <el-menu-item disabled index="3" :class="activeIndex == 3 ? 'menu-select' : ''">
        <div class="enum-item el-menu-tooltip__trigger">
          <el-icon class="icon-item">
            <svg-icon class="icon" icon-class="robot" />
          </el-icon>
          <span>仿真</span>
        </div>
      </el-menu-item>
      <el-menu-item index="4" :disabled="!(userInfo?.perms || []).includes('app:ide:page:laborer')" :class="activeIndex == 4 ? 'menu-select' : ''">
        <div class="enum-item el-menu-tooltip__trigger">
          <el-icon class="icon-item">
            <svg-icon class="icon" icon-class="impl" />
          </el-icon>
          <span>配置</span>
        </div>
      </el-menu-item>
      <div style="position: absolute; bottom: 0;">
        <div class="logo">
          <div class="head"></div>
        </div>
      </div>
    </el-menu>
    <div @click="onLogoClick" class="info-btn">
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineEmits } from 'vue'
import { useWork } from "@/store/modules/work";
import { oneMessage } from '@/utils/one_message'
import { getUserInfo } from '@/service/user'
import { UserInfo } from '@/types';
import Router from "@/router";

const userInfo = ref<UserInfo>()
const emit = defineEmits(['openProject', 'handleSelect'])
const curPage = computed(()=> Router.currentRoute.value.name)
const activeIndex = computed(() => {
  if (curPage.value == "design") {
    return 1
  } else if (curPage.value === "program") {
    return 2
  } else if (curPage.value === "laborer") {
    return 4
  }
})
const isCollapse = ref(true)
function handleOpen(key: string, keyPath: string[]) {
  console.log(key, keyPath)
}

function handleClose(key: string, keyPath: string[]) {
  console.log(key, keyPath)
}
function handleSelect(key: number, keyPath: string[]) {
  if (key == 0) {
    emit('openProject')
    return
  } else if (key == 1) {
    emit('handleSelect', 'design')
  } else if (key == 2) {
    emit('handleSelect', 'program')
  } else if (key == 4) {
    emit('handleSelect', 'laborer')
  }
}

let clkCount = 0
function onLogoClick() {
  clkCount++
  if (clkCount === 6) {
    oneMessage(location.host)
    clkCount = 0
  }
}
onBeforeMount( async ()=> {
  userInfo.value = await getUserInfo()
  console.log('userInfo.value', userInfo.value)
})
</script>

<style>
.el-menu-item .el-menu-tooltip__trigger {
  padding: 0 10px;
}
</style>
<style scoped lang="scss">
.left-bar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  text-align: center;
}

.el-menu-vertical {
  width: 200px;
  height: 100%;
  min-height: 400px;
  /*background-color: #D2D2E3;*/
}

.el-menu--collapse {
  width: 44px;
}

.enum-item {
  /*display: flex;*/
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon-item {
  font-size: 30px;
  width: 30px;
}

.el-menu-item {
  height: 60px;
  line-height: 20px;
  border-radius: 20px 0 0 20px;
  margin-top: 30px;
}

.menu-select {
  background-color: #393B83;
}

.el-menu-item span {
  font-size: 12px;
  color: #393B83;
}

.menu-select span {
  color: white;
}

.el-menu-item:hover {
  background-color: #9798dd;
}

.el-menu-item:hover span {
  color: white;
}

.logo {
  margin-bottom: 20px;
}

.head {
  margin-left: 2px;
  border-radius: 50px;
  background: gray;
}

.image {
  margin-top: 5px;
  padding: 20px;
  mask-image: url("~@/assets/Logo.png");
  background: #1c1e63;
  mask-size: 40px;
  mask-position: top;
  mask-repeat: no-repeat;
}

.icon {
  border-radius: 20px;
  /*background-color: #1c1e63;*/
  fill: #393B83;
}

.el-menu-item:hover .icon {
  fill: white;
}

.menu-select .icon {
  fill: white;
}

.design_image {}

.info-btn {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 40px;
  height: 40px;
}

::v-deep(.is-disabled) {
  background: var(--el-menu-hover-bg-color) !important;
  opacity: 0.5;
}
</style>
