$--point-list-bg-color: #F4F4F4;

.select-tip {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  flex-shrink: 0;
}

.position {
  display: flex;
  gap: 5px;
  align-items: center;
}


.card {
  display: flex;
  flex-direction: column;

  div {
    span {
      width: 15px;
    }
  }
}

.tipsTxt {
  font-size: 12px;
  color: #393b83;
  text-align: left;
}

#RobotSliderArea {
  width: 100%;
}

/*.input-number {*/
/*  width: 65px;*/
/*}*/

::v-deep .el-input--default {
  .el-input__inner {
    height: 25px;
    background: #8E90D7;
    color: #ffffff;
  }

}

::v-deep .el-input--suffix {
  .el-input__inner {
    height: 25px;
    background: #ffffff;
    color: #8E90D7;
  }

  &::placeholder {
    color: black;
  }
}

.varDeclareItemClass {
  // margin-right: 20px;
  margin-bottom: 20px;
}

.el-row {
  margin-bottom: 0px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  // background: #99a9bf;
}

.bg-purple {
  // background: #d3dce6;
}

.bg-purple-light {
  // background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
}


::v-deep .el-input.is-disabled .el-input__inner {
  color: #ffffff;
  height: 25px;
  background-color: #8E90D7;

  /* change element ui placeholder color */
  /* .placeholder {
    color: #ffffff;
  } */
}
::v-deep(.el-card.is-always-shadow) {
  box-shadow: none;
}
.basic-panel-wrap {
  /*gap: 5px;*/
  display: flex;
  flex-direction: column;
  /*text-align-last: start;*/
  .panel + .panel {
    margin-top: 5px;
  }
  .panel + .basic-sec-wrap{
    margin-top: 5px;
  }

  height: 100%;
}
  #machine-item-mobile-wrap {
    display:none;
  }
  .pose-panel-wrap {
    margin-top: 5px;
  }
  .basic-sec-wrap {
    flex: 1;
  }
