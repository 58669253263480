$--point-list-bg-color: #F4F4F4;
@import "./variables.scss";
html,
body {
  --el-color-primary: #7a7cbf;
  --el-color-primary-light-3: var(--el-color-primary);
  --el-color-primary-light-5:var(--el-color-primary);
  --el-color-primary-light-9: #ecedff;
  --el-color-dark: #393B83;
  --el-color-editable-text: #1c1e63;
  --status-bar-height:18px;
  --header-height: 42px;
  --menu-height: 25px;
  margin: 0;
  width: 100%;
  height: 100%;
}

.el-collapse-item__content {
  color: #1c1e63 !important;
}
.el-select__wrapper,
.el-input__wrapper {
  background: #ecedff !important;
  padding: 1px 3px;
}
.el-select__inner,
.el-input__inner {
  background: #ecedff !important;
}
.el-card__body {
  padding: 10px !important;
}
.el-input-number.is-without-controls .el-input__inner {
  padding: 0 4px 0 4px !important;
  text-align-last: right;
}
.el-input-number.is-without-controls .el-input__wrapper {
  padding-left: 2px !important;
  padding-right: 2px !important;
}
.el-dropdown-menu__item:not(.is-disabled):focus {
  background-color: var(--el-bg-color);
  color: black;
}
.el-select,
.el-input.is-focus,
.el-input__inner,
.el-input:hover,
.el-input__inner,
.is-focus {
  box-shadow: none !important;
  --el-select-input-focus-border-color: none !important;
}

* {
  -webkit-user-select: none !important;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  outline: none !important;
  user-select: none !important;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: var(--el-color-primary-light-9);
  border-radius: 3px;
}


.top-bar {
  border: 1px solid var(--el-color-primary-light-9);
  border-style: dotted double;
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 10px;

  .el-dropdown-link span {
    justify-content: space-between;
    min-width: 150px;
  }
}

.update-notification {
  left: calc((100% - var(--el-notification-width)) / 2);
  transition: opacity 0,transform 0,left 0,right 0,top .4s,bottom var(--el-transition-duration);
  top: 5px !important;
}

.mobile-body-size {
  width: 1100px!important;
  height: 513px!important;
}
.el-popper {
  width: auto!important;
}
