<template>
  <div class="pose-panel-wrap">
    <div class="axis-panel-wrap">
      <div style="display: flex;justify-content: space-between;align-items: center">
        <span>{{ title }}</span>
        <el-button @click="handleBackZero" size="small">回零</el-button>
      </div>
      <div class="slider-bar-wrap" style="margin-top: 5px">
        <div v-for="(item, index) in robot?.axisAngles" :key="`axis${index}`">
          <slider :onSliderChange="onSliderChangeCallback"
                  :item="item"
                  @update:item="(value)=>onSliderChangeCallback(item, value)"
                  :minLimit="-deviceMinCfg[index] || 0"
                  :maxLimit="deviceMaxCfg[index] || 360"
                  :name="`A${index+1}`"
                  :isShowLocked="true"
          ></slider>
        </div>
      </div>
      <div class="dashed-line">
      </div>
    </div>
    <div class="end-adjustment-wrap">
      <span style="display: block;line-height: 24px">末端调整</span>
      <div style="display: flex; gap:2px; align-items: center; margin-top: 5px;">
        <span style="flex-shrink: 0">当前TCP</span>
        <el-select class="ucs-select" v-model="curTcpId" size="small">
          <el-option v-for="tcp in tcpOptions" :key="tcp.id" :label="tcp.name" :value="tcp.id"/>
        </el-select>
        <span style="flex-shrink: 0">参考坐标系</span>
        <el-select style="margin-left: 5px;flex:1" v-model="reference" :disabled="curTcp?.isTcpOrigin" size="small">
          <el-option v-for="(item, index) in coordinateSystem" :key="index" :label="item.label" :value="item.value">
            {{ item.label }}
          </el-option>
        </el-select>
      </div>
      <div style="display: flex; align-items: center; gap: 5px; width: 100%">
        <el-checkbox style="width: 15px" v-model="robot!.isLockedTcp"></el-checkbox>
        <div style="width:calc(100% - 15px)">
          <div class="position">
            <span class="input-label">X</span>
            <el-input-number class="input-number"
                             v-model="(curTcp||{}).x"
                             :precision="2"
                             :step="0.1"
                             placeholder="inputX"
                             :value-on-clear="0"
                             :controls="false"
                             :disabled="true"
                             size="small"/>
            <span class="input-label">Y</span>
            <el-input-number class="input-number"
                             v-model="(curTcp||{}).y"
                             :precision="2"
                             :step="0.1"
                             placeholder="inputY"
                             :value-on-clear="0"
                             :controls="false"
                             :disabled="true"
                             size="small"/>
            <span class="input-label">Z</span>
            <el-input-number class="input-number"
                             v-model="(curTcp||{}).z"
                             :precision="2"
                             :step="0.1"
                             placeholder="inputZ"
                             :value-on-clear="0"
                             :controls="false"
                             :disabled="true"
                             size="small"/>
          </div>
          <div class="position">
            <span class="input-label">Rx</span>
            <el-input-number class="input-number"
                             v-model="(curTcp||{}).rx"
                             :precision="4"
                             :step="0.1"
                             placeholder="inputX"
                             :value-on-clear="0"
                             :controls="false"
                             :disabled="true"
                             size="small"/>
            <span class="input-label">Ry</span>
            <el-input-number class="input-number"
                             v-model="(curTcp||{}).ry"
                             :precision="4"
                             :step="0.1"
                             placeholder="inputY"
                             :value-on-clear="0"
                             :controls="false"
                             :disabled="true"
                             size="small"/>
            <span class="input-label">Rz</span>
            <el-input-number class="input-number"
                             v-model="(curTcp||{}).rz"
                             :precision="4"
                             :step="0.1"
                             placeholder="inputZ"
                             :value-on-clear="0"
                             :controls="false"
                             :disabled="true"
                             size="small"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang='ts'>
import {defineProps} from 'vue'
import {Robot, ENTITY_TYPE, Project, Machine} from "@/model";
import Slider from './SliderItem.vue'
import {useWork} from "@/store/modules/work";

const props = defineProps({
  robot: {
    type: Robot,
    require: true
  },
  title: {
    type: String,
    default: ''
  }
})
const store = useWork()

const curTcpId = ref('')
const tcpOptions = computed(() => props.robot?.tcps)
const curTcp = computed(() => props.robot!.tcps.find(e => e.id === curTcpId.value))
const coordinateSystem = computed(() => {
  return curTcp.value!.getUcs().map(e => {
    return {label: e.name, value: e.id}
  })
})
watchEffect(() => {
  if (props.robot) {
    curTcpId.value = props.robot?.tcp0.id
  }
})
const reference = computed({
  get() {
    if (curTcp.value?.reference?.frame != 'ucs') {
      return curTcp.value?.reference?.frame || coordinateSystem.value![0].value || 0
    } else {
      return curTcp.value?.originMachine.id
    }
  },
  set(val: string) {
    if (curTcp.value?.type === ENTITY_TYPE.UCS || curTcp.value?.type === ENTITY_TYPE.TCP) {
      if (['global', 'base', 'tool'].includes(val)) {
        curTcp.value?.setReference(val as 'global')
      } else {
        let origin = Project.current()!.findTargetById<Machine>(val);
        if (origin) {
          curTcp.value?.setReference('ucs', val);
        } else {
          console.error('origin is null')
        }
      }
    }
    return curTcp.value?.reference.frame
  }
})
const deviceMaxCfg = computed(() => {
  if (props.robot && props.robot.model && props.robot.model.maxLimit) {
    return props.robot.model.maxLimit
  } else {
    return []
  }
})
const deviceMinCfg = computed(() => {
  if (props.robot && props.robot.model && props.robot.model.minLimit) {
    return props.robot.model.minLimit
  } else {
    return []
  }
})

function onSliderChangeCallback(item: any, val: number) {
  item.val = val
}

function handleBackZero() {
  props.robot!.axisAngles.forEach((axis, index) => {
    axis.val = props.robot!.model.zero_angle[index]
  })
}
</script>

<style scoped>


.ucs-select {
  width: 100px;
}
.pose-panel-wrap {
  font-size: 12px;
}
.position {
  margin-top: 5px;
  display: flex;
  gap: 5px;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
}


.input-label {
  width: 20px;
  flex-shrink: 0;
}

.dashed-line {
  border-top: 1px dashed var(--el-border-color);
  margin-top: 3px
}
</style>