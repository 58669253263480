$--point-list-bg-color: #F4F4F4;

$--ctrl-btn-width: 260px;
$--ctrl-btn-height: 260px;
.ctrl-wrap {
  position: relative;
}

.outside-horizontal-img {
  width: 260px * 0.619;
  height: 132px * 0.619;
}
.outside-vertical-img {
  width: 132px * 0.619;
  height: 260px * 0.619;
}
.speed-img {
  width: 120px * 0.619;
  height: 120px * 0.619;
}

.img-flex {
 display: flex;
 justify-content: center;
 align-items: center;
 gap: 5px;
}
.top-img {
  @extend .img-flex;
}

.center-img {
  @extend .img-flex;
  flex: 0;
  position: relative;
  top: -52px*0.619;
}

.bottom-img {
  @extend .img-flex;
  position: relative;
  top: -52px*2*0.619;
}

.ctrl-img-wrap {
  box-sizing: border-box;
  width: 260px;
  height: 260px;
  display: flex;
  flex-direction: column;
  position: relative;

  img {
    user-select: none;
  }
}
.ctrl-btn-wrap {
  @extend .ctrl-img-wrap;
  position: absolute;
  top: 0;
  left: 0;
  >div {
    width: 55px;
    height: 55px;
    position: absolute;
  }

  $--horizontal-btn-width: 80px;
  $--vertical-btn-height: 80px;
  .up-btn {
    width: $--horizontal-btn-width;
    top: 10px;
    left: ($--ctrl-btn-width - $--horizontal-btn-width) / 2;
  }
  .left-btn {
    height: $--vertical-btn-height;
    left: 15px;
    top: ($--ctrl-btn-height - $--vertical-btn-height) / 2;
  }
  .speed-btn {
    top: ($--ctrl-btn-height - $--vertical-btn-height) / 2 + 15px;
    left: ($--ctrl-btn-width - $--horizontal-btn-width) / 2 + 15px;
    .speed-text {
      width: 100%;
      font-size: 16px;
      color: rgb(216, 217, 230);
      position: absolute;
      bottom: 1px;
      text-align: center;
    }
  }
  .right-btn {
    height: $--vertical-btn-height;
    right: 15px;
    top: ($--ctrl-btn-height - $--vertical-btn-height) / 2;
  }
  .down-btn {
    width: $--horizontal-btn-width;
    bottom: 10px;
    left: ($--ctrl-btn-width - $--horizontal-btn-width) / 2;
  }
}
