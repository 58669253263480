$--point-list-bg-color: #F4F4F4;

  $--item-list-height: 188px;

  .path-item-wrap {
    box-sizing: border-box;
    // border-radius: 0px 0px 10px 10px;
    border: 0.5px solid var(--el-color-primary-light-5);
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25);
  }
  .path-item-header{
    box-sizing: border-box;
    padding: 5px;
    border-bottom: 0.5px solid var(--el-color-primary-light-5);
    display: flex;
    align-items: center;
    // border-radius: 8px 8px 0px 0px;
    background-color: var(--el-color-primary-light-5);
    border-bottom: 1px solid var(--el-color-primary-light-5);
    height: 30px;
    color: white;
  }
  .path-item-content {
    box-sizing: border-box;
    padding: 5px;
    display: flex;
    gap: 5px;
  }
  .path-item-img {
    width: 250px;
    height: $--item-list-height;
  }
  .path-item-list {
    flex-grow: 1;
    overflow: auto;
    height: $--item-list-height;
    display: flex;
    gap: 5px;
    flex-direction:column;
  }
  .point-item {
    display: flex;
    justify-content: space-between;
  }
  .point-del-btn {
    text-align: right;
  }
  ::v-deep(.el-table__header-wrapper) {
    display: none;
  }
  ::v-deep(.el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf) {
    border: none;
  }
  ::v-deep(.el-table__inner-wrapper::before) {
    box-sizing: border-box;
    display: none;
    overflow: hidden;
  }
  ::v-deep(.el-table__row) {
    // border-radius: 8px;
    border: 1px solid #1c1e63;
  }
  ::v-deep(.el-collapse-item__header.is-active) {
    @extend .path-item-header;
  }
  ::v-deep(.el-collapse-item__header) {
    @extend .path-item-header;
    border-radius: 5px 5px 0px 0px;
  }
  ::v-deep(.el-collapse-item__content) {
    // @extend .path-item-wrap;
  }
  ::v-deep(.el-collapse-item__wrap) {
    // border-radius: 0px 0px 8px 8px;
    border: 0px solid var(--el-collapse-border-color);
  }
  ::v-deep(.el-collapse) {
    border: 0px solid var(--el-collapse-border-color);
  }
  ::v-deep(.el-collapse-item__content) {
    padding-bottom: 0px;
  }
