$--point-list-bg-color: #F4F4F4;

$--gap: 3px;
#items-wrap {
  display: flex;
  gap: $--gap + 2px;
  flex-direction: column;
  overflow: auto;
}
.coord-name {
  display: flex;
  gap: $--gap;
}
.point-name{
  display: flex;
  gap: $--gap;
}
.coord-name+.point-name {
  margin-top: $--gap;
}
::v-deep(.el-form-item) {
  margin-bottom: 5px;
}
